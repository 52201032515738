<template>
  <div :key="updateKey">
    <div class="flex flex-gap-5">
      <div>
        <flixIcon :id="'filter'"></flixIcon
        ><a href="#" @click.prevent="setCheckAll(true)" class="flix-html-a">
          {{ clients.length }} {{ $tc('message.result', 2) }}</a
        >
      </div>
      <div>
        <flixIcon :id="'filter'"></flixIcon
        ><a
          href="#"
          @click.prevent="setCheckAll(false)"
          class="flix-html-a"
          style="text-decoration: line-through"
        >
          {{ $tc('message.customer', clients.length) }}</a
        >
      </div>
      <div>
        <flixIcon :id="'filter'"></flixIcon>
        <a
          href="#"
          @click.prevent="setCheckAll('commitment')"
          class="flix-html-a flix-text-bold"
          >{{ $t('message.commitment') }}</a
        >
      </div>
      <div>
        <flixIcon :id="'filter'"></flixIcon>
        <a
          href="#"
          @click.prevent="setCheckAll('cancellation')"
          class="flix-html-a"
          >{{ $t('message.cancellation') }}</a
        >
      </div>
    </div>
    <div v-for="(client, index) in clients" :key="index">
      <div class="flixTable">
        <div class="flixItem check">
          <div class="flixTitle" v-if="index === 0">
            <a @click.prevent="" class="flix-html-a"
              ><flixIcon :id="'check'"
            /></a>
          </div>
          <div :key="updateChecked">
            <a
              href="#"
              @click.prevent="setChecked(client.booking_id)"
              class="flix-html-a"
              ><flixIcon :id="getCheckedIcon(client.booking_id)"
            /></a>
          </div>
        </div>
        <div class="flixItem id">
          <div class="flixTitle" v-if="index === 0">#</div>
          <div>
            {{ client.booking_id }}
          </div>
        </div>
        <div class="flixItem calendar" v-if="!id || id == 'all'">
          <div class="flixTitle" v-if="index === 0 && (!id || id == 'all')">
            <flixIcon :id="'flag'" /> {{ $tc('message.calendar', 1) }}
            <orderBtn
              :active="getActive('up', 'calendar')"
              :id="'up'"
              :onClick="
                (direction) => {
                  setReorder(direction, 'calendar')
                }
              "
            /><orderBtn
              :active="getActive('down', 'calendar')"
              :id="'down'"
              :onClick="
                (direction) => {
                  setReorder(direction, 'calendar')
                }
              "
            />
          </div>
          <div
            :style="getColor()"
            v-html="$store.getters.assistentsByID[client.form].title"
          ></div>
        </div>
        <div class="flixItem mail">
          <div class="flixTitle" v-if="index === 0">
            <flixIcon :id="'user'" /> {{ $tc('message.customer', 1) }}
          </div>
          <div :style="getColor()" v-html="getData(client)"></div>
        </div>
        <div class="flixItem date">
          <div class="flixTitle" v-if="index === 0">
            <flixIcon :id="'calendar'" /> {{ $tc('message.appointments', 1) }}
            <orderBtn
              :active="getActive('up', 'appointments')"
              :id="'up'"
              :onClick="
                (direction) => {
                  setReorder(direction, 'appointments')
                }
              "
            /><orderBtn
              :active="getActive('down', 'appointments')"
              :id="'down'"
              :onClick="
                (direction) => {
                  setReorder(direction, 'appointments')
                }
              "
            />
          </div>
          <div :style="getColor()" v-html="client.booking_date"></div>
        </div>
        <div class="flixItem checkin">
          <div class="flixTitle" v-if="index === 0">
            <flixIcon id="exit" /> {{ $tc('message.checkIn', 1) }}
          </div>
          <checkInClient :client="client" />
        </div>
        <div class="flixItem status">
          <div class="flixTitle" v-if="index === 0">
            <flixIcon :id="'thumbs-up'" /> {{ $tc('message.status', 1) }}
            <orderBtn
              :id="'up'"
              :active="getActive('up', 'status')"
              :onClick="
                (direction) => {
                  setReorder(direction, 'status')
                }
              "
            /><orderBtn
              :active="getActive('down', 'status')"
              :id="'down'"
              :onClick="
                (direction) => {
                  setReorder(direction, 'status')
                }
              "
            />
          </div>
          <div
            class="flix-html-html"
            :class="'flix-bg-' + $getStatusClass(client.status)"
            v-html="$t('message.' + client.status)"
          ></div>
        </div>
        <div class="flixItem edit">
          <div class="flixTitle" v-if="index === 0">
            {{ $t('message.edit', { name: '' }) }}
          </div>
          <span>
            <a
              href="#"
              class="flix-btn flix-btn-default"
              :class="{ 'flix-btn-info': getEdit(index) }"
              @click.prevent="setEdit(index)"
              ><flixIcon :id="'edit'"
            /></a>
            <!-- <router-link :to="{name: getEditLink(), params: { user: variables.user.md5_id, form: client.form, entryID: client.md5ID }}" href="#" class="flix-btn flix-btn-info" :title="$tc('message.settings', 2)"><flixIcon :id="'cogwheel'" /></router-link> -->
          </span>
        </div>
      </div>
      <div class="flixTable flix-active" v-if="getEdit(index)">
        <div style="width: 100%">
          <div class="flix-form-group flix-text-center">
            <b-flix-h2 variant="green">
              {{ $tc('message.appointments', 1) }} #{{ client.booking_id }}
            </b-flix-h2>
          </div>
          <div class="flix-form-group">
            <div class="flix-row">
              <div
                class="flix-col-md-4"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-wrap: wrap;
                  flex-direction: column;
                  gap: 10px;
                "
              >
                <b-flix-h1 style="display: block">
                  <flix-icon id="calendar"></flix-icon>
                </b-flix-h1>
                <div>
                  <bookingflix-calendar-items
                    style="font-weight: bold"
                    :settings="getJsonDate(client)"
                  ></bookingflix-calendar-items>
                  <bookingDate
                    style="
                      gap: 10px;
                      display: flex;
                      align-items: left;
                      justify-content: flex-start;
                    "
                    :entry="client"
                    :key="$store.getters.bookingCalendarHash"
                    :callback="
                      (ret) => {
                        clients[index] = ret
                        updateKey++
                      }
                    "
                  />
                </div>
              </div>
              <div
                class="flix-col-md-4"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-wrap: wrap;
                  flex-direction: column;
                  gap: 10px;
                "
              >
                <b-flix-h1 style="display: block">
                  <flix-icon
                    :id="client.group == 1 ? 'user' : 'group'"
                  ></flix-icon>
                </b-flix-h1>
                <changeGroup
                  :user="$getUserVariables().user.md5_id"
                  :client="client"
                />
              </div>
              <div
                class="flix-col-md-4"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-wrap: wrap;
                  flex-direction: column;
                  gap: 10px;
                "
              >
                <b-flix-h1 style="display: block">
                  <flix-icon id="exit"></flix-icon>
                </b-flix-h1>
                <bookingCheckIn
                  :entry="client"
                  :callback="
                    (ret) => {
                      clients[index] = ret
                      updateKey++
                    }
                  "
                ></bookingCheckIn>
              </div>
            </div>
          </div>
          <div class="details">
            <div class="flix-form-group">
              <bookingStatus
                :entry="client"
                :callback="
                  (ret) => {
                    setClient(index, ret)
                  }
                "
              />
            </div>
            <div class="flix-form-group" v-if="client.service">
              <div class="flix-list-group">
                <div class="flix-list-group-item">
                  <b>{{ $tc('message.service', 1) }}:</b>
                  {{ client.service.title }}
                </div>
                <div
                  class="flix-list-group-item"
                  v-if="client.service.description"
                  v-html="client.service.description"
                ></div>
                <div
                  class="flix-list-group-item"
                  v-if="client.service.price"
                  v-html="client.service.price + ' EUR'"
                ></div>
              </div>
            </div>
            <div class="flix-form-group">
              <entryData :entry="client" />
            </div>
            <!-- <div class="flix-form-group">
            <checkIn :data="getCheckinData(client)" :callback="function () { }" />
          </div> -->
            <div class="flix-form-group">
              <router-link
                :to="{
                  name: getEditLink(),
                  params: {
                    user: variables.user.md5_id,
                    form: client.form,
                    entryID: client.md5ID
                  }
                }"
                href="#"
                class="flix-btn flix-btn-info"
                :title="$tc('message.settings', 2)"
                >{{
                  $t('message.goTo', {
                    name: $tc('message.customer', 1) + ' #' + client.booking_id
                  })
                }}</router-link
              >
            </div>
            <div class="flixTable flix-active flix-text-center">
              <div class="flixItem">
                <div class="">
                  <b-flix-h1 style="display: block; text-align: center">
                    <flix-icon id="server"></flix-icon>
                  </b-flix-h1>
                  <b-flix-p
                    >Server-Logs
                    {{
                      $tc('message.customer', 1) + ' #' + client.booking_id
                    }}</b-flix-p
                  >
                </div>
              </div>
            </div>

            <loggingTable :backButton="false" :ID="client.ID"></loggingTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    loggingTable() {
      return import('@/components/logs/loggingTable')
    },
    bookingDate() {
      return import('@/components/booking/changeDate/index')
    },
    entryData() {
      return import('@/components/booking/data')
    },
    orderBtn() {
      return import('@/components/crm/orderBtn')
    },
    bookingStatus() {
      return import('@/components/booking/status')
    },
    bookingCheckIn() {
      return import('@/components/booking/checkIn/index.vue')
    },
    changeGroup() {
      return import('@/components/booking/changeGroup')
    },
    // checkIn () { return import('@/components/checkIn/page/edit/') },
    checkInClient() {
      return import('./checkIn/')
    }
  },
  props: {
    id: [String, Number],
    clients: Array,
    updateChecked: Number,
    clicked: Array,
    filter: [String, Date],
    setChecked: Function,
    getChecked: Function,
    setReorder: Function,
    setCheckAll: Function,
    setClient: Function
  },
  data() {
    return {
      updateKey: 0,
      variables: this.$getUserVariables(),
      edit: false
    }
  },
  computed: {},
  mounted() {
    this.setCheckAll('commitment')
  },
  methods: {
    getJsonDate(client) {
      return JSON.stringify({
        lang: this.$i18n.locale,
        view: 'date-string',
        date: [client.begin, client.end]
      })
    },
    getBookingDate(client) {
      return client.booking_date + ' | ' + this.$getGroupTxt(client, this)
    },
    getCheckinData(client) {
      return Object.assign(client, {
        atitle: JSON.stringify({ user: this.variables.user.md5_id })
      })
    },
    getData(client) {
      var r = []

      if (client.service && client.service.title) {
        r.push(
          '<b style="font-size: 8pt">' +
            this.$tc('message.service', 1) +
            '</b>: ' +
            client.service.title
        )
      }

      this.$manualAppointment(client)
        ? r.push(this.$manualAppointment(client))
        : this.$manualAppointment(client)

      r.push(
        '<i class="flix-glyphicon flix-glyphicon-' +
          (client.group.toString() === '1' ? 'user' : 'parents') +
          '"></i> ' +
          client.group +
          ' ' +
          this.$tc('message.customer', client.group * 1)
      )

      // tooltip = tooltip[1].split('\'')[1]

      Object.keys(client.data).forEach((key) => {
        let content = JSON.parse(JSON.stringify(client.data[key]))
        if (typeof content.src !== 'undefined') {
          content =
            '<a href="' +
            content.src +
            '" target="_blank" class="flix-html-a">' +
            content.src +
            '</a>'
        }
        r.push('<b style="font-size: 8pt">' + key + '</b>: ' + content)
      })

      return r.join(' | ')
    },
    getColor() {
      if (
        typeof this.client === 'undefined' ||
        typeof this.client.form === 'undefined'
      ) {
        return false
      }
      if (
        typeof this.$store.getters.assistentsByID[this.client.form].color !==
        'undefined'
      ) {
        return {
          color: this.$store.getters.assistentsByID[this.client.form].color
        }
      }
      if (
        typeof this.colors !== 'undefined' &&
        typeof this.colors[this.client.form] !== 'undefined'
      ) {
        return { color: this.colors[this.client.form] }
      }
    },
    getCheckedIcon(index) {
      if (!this.getChecked(index)) {
        return 'unchecked'
      }
      return 'check'
    },
    getActive(direction, filter) {
      if (this.clicked[0] === direction && this.clicked[1] === filter) {
        return true
      }
    },
    setEdit(index) {
      if (this.edit === index) {
        this.edit = false
        return false
      }
      this.edit = index
    },
    getEdit(index) {
      if (this.edit === index) {
        return true
      }
      return false
    },
    getEditLink() {
      var name = 'dashboardBookingDetails'
      if (this.variables.state === 'share') {
        name = 'sharesBookingDetails'
      }
      return name
    }
  }
}
</script>
<style lang="sass" scoped>
.flixTable
  display: flex
  flex-direction: row
  align-items: flex-start
  justify-content: center
  gap: 10px
  &.flix-active
    margin-top: 10px
    background-color: #f2f2f2
    padding: 10px
    box-sizing: border-box
  div.flixItem
    flex: 1
    display: flex
    align-items: flex-start
    justify-content: center
    flex-direction: column
    min-height: 50px
    font-size: 10pt
    gap: 10px
    div, span
      border-top: 1px solid #f2f2f2
      padding-top: 5px
      width: 100%
      display: block
    .flixTitle
      border-top: 1px solid transparent
      font-size: 12pt
      font-weight: bold
      text-align: left
      justify-content: flex-start
      align-items: center
      display: flex
      gap: 3px
    &.check
      max-width: 100px
      a
        padding-left: 10px
    &.mail
      overflow-y: hidden
      overflow-x: hidden
      white-space: nowrap
      &:hover
        overflow-x: auto

    &.id
      max-width: 80px
      overflow: hidden
    &.status
      max-width: 130px
      text-align: center
    &.checkin
      max-width: 100px
    &.edit
      text-align: center
      max-width: 100px

@media (max-width: 800px)
  .flixTableContainer
    overflow-x: hidden
    width: 100%
    .flixTable
      overflow-x: scroll
      justify-content: flex-start
      .flixTitle
        height: 80px
        display: flex
        align-items: center
        font-size: 10pt
      div.flixItem
        &.mail, &.tel, &.id, &.checkin
          display: none
</style>
